var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flow-center-container" },
    [
      _c(
        "el-tabs",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tabLoading,
              expression: "tabLoading",
            },
          ],
          on: {
            "tab-click": function ($event) {
              return _vm.getWorkFlowList(_vm.workFlowName)
            },
          },
          model: {
            value: _vm.workFlowName,
            callback: function ($$v) {
              _vm.workFlowName = $$v
            },
            expression: "workFlowName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "wait" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("lang_to_do_list")) + " "),
                  _vm.todoTotal > 0
                    ? _c("el-badge", {
                        staticClass: "mark",
                        attrs: { value: _vm.todoTotal },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.todoList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                style: {
                                  width: "calc(100% - 540px)",
                                  "font-weight": !item.readStatus ? 900 : "",
                                },
                                attrs: { title: item.title },
                              },
                              [
                                item.preSubmitType == "3"
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#ed4014" } },
                                      [_vm._v("【退回】")]
                                    )
                                  : _vm._e(),
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                                !item.readStatus
                                  ? _c("img", {
                                      staticClass: "item-icon--unread",
                                      attrs: {
                                        src: require("@/assets/image/door/new_icon_blue_32.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.startDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatterDate(item.startDate)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.startUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.startUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.todoList.length == 0
                      ? _c("li", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { staticClass: "cus-tab-item", attrs: { name: "start" } },
            [
              _c("template", { slot: "label" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_initiating_matters")) + " "),
                _c("span", [_vm._v("(" + _vm._s(_vm.startTotal) + ")")]),
              ]),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.startList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                item.endDate
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#409eff" } },
                                      [
                                        _vm._v(
                                          "【" +
                                            _vm._s(_vm.$t("lang_end")) +
                                            "】"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.startDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatterDate(item.startDate)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.startUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.startUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.startList.length == 0
                      ? _c("li", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "done" } },
            [
              _c("template", { slot: "label" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_done_items")) + " "),
                _c("span", [_vm._v("(" + _vm._s(_vm.doneTotal) + ")")]),
              ]),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.doneList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.completedDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatterDate(item.completedDate)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.startUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.startUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.doneList.length == 0
                      ? _c("li", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "finished" } },
            [
              _c("template", { slot: "label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("lang_matters_to_be_settled")) + " "
                ),
                _c("span", [_vm._v("(" + _vm._s(_vm.finishedTotal) + ")")]),
              ]),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.finishedList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.endDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.formatterDate(item.endDate))
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.endUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.endUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.finishedList.length == 0
                      ? _c("div", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "carbon" } },
            [
              _c("template", { slot: "label" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_cc_matters")) + " "),
                _c("span", [_vm._v("(" + _vm._s(_vm.carbonTotal) + ")")]),
              ]),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.carbonList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                item.endDate
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#409eff" } },
                                      [_vm._v("【完结】")]
                                    )
                                  : _vm._e(),
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                                !item.readStatus
                                  ? _c("img", {
                                      staticClass: "item-icon--unread",
                                      attrs: {
                                        src: require("@/assets/image/door/new_icon_blue_32.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.ccDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.formatterDate(item.ccDate))
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.ccUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.ccUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.carbonList.length == 0
                      ? _c("div", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "transfer" } },
            [
              _c("template", { slot: "label" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_transfer_items")) + " "),
                _c("span", [_vm._v(" (" + _vm._s(_vm.transferTotal) + ") ")]),
              ]),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.transferList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                item.endDate
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#409eff" } },
                                      [_vm._v("【完结】")]
                                    )
                                  : _vm._e(),
                                item.parentsProcessName &&
                                item.parentsProcessName.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.title) +
                                          " -【" +
                                          _vm._s(item.parentsProcessName) +
                                          "】"
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.delegateDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatterDate(item.delegateDate)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.delegateUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.delegateUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    !_vm.transferList.length
                      ? _c("div", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "possibility" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("lang_predict_events_in_advance")) + " "
                  ),
                  _vm.possibilityTotal > 0
                    ? _c("el-badge", {
                        staticClass: "mark",
                        attrs: { value: _vm.possibilityTotal },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "work-folw-wrap" }, [
                _c(
                  "ul",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.possibilityList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.openApprove(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-title",
                                staticStyle: { width: "calc(100% - 540px)" },
                                attrs: { title: item.title },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.title +
                                        "-" +
                                        item.possibilityNodeName
                                    )
                                  ),
                                ]),
                                !item.readStatus
                                  ? _c("img", {
                                      staticClass: "item-icon--unread",
                                      attrs: {
                                        src: require("@/assets/image/door/new_icon_blue_32.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-time",
                                staticStyle: { width: "300px" },
                              },
                              [
                                item.startDate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatterDate(item.startDate)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item-publisher",
                                staticStyle: { width: "80px" },
                              },
                              [
                                item.possibilityUserName
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.possibilityUserName)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.possibilityList.length == 0
                      ? _c("div", { staticClass: "loadMore" }, [
                          _vm._v(_vm._s(_vm.$t("lang_no_data"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.isShowMore
        ? _c("span", { staticClass: "more", on: { click: _vm.showMore } }, [
            _vm._v(" " + _vm._s(_vm.$t("lang_more")) + " >>"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }