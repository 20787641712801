




























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DateUtil from '@/utils/DateUtil';
import HomePage from '@/api/home/homePage';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'FlowCenter'
})
export default class FlowCenter extends Vue {
  homePage: HomePage = new HomePage();
  tab: number = 0;
  currentServer: string = process.env.VUE_APP_CURRENTSERVER;
  todoList: any[] = []; // 待办列表
  todoTotal: number = 0;
  doneList: any[] = []; // 已办列表
  doneTotal: number = 0;
  startList: any[] = []; // 发起列表
  startTotal: number = 0;
  finishedList: any[] = []; //办结列表
  finishedTotal: number = 0;
  carbonList: any[] = []; //抄送列表
  carbonTotal: number = 0;
  transferList: any[] = []; //转办列表
  transferTotal: number = 0;
  possibilityList: any[] = []; //提前预知事项列表
  possibilityTotal: number = 0;
  moreList: any[] = []; //更多列表
  workflowSeize: number = 23;
  workFlowName: String = 'wait';
  pageIndex: number = 1;
  factoryProcessList: any[] = []; // 常用流程
  pageSize: number = 10;
  page: any = { pageIndex: 1, pageSize: 20, total: 0 };
  isShowWait: boolean = false; //代办标签是否展示
  isShowStart: boolean = false; //发起标签是否展示
  isShowDone: boolean = false; //已办标签是否展示
  isShowFinished: boolean = false; //完结标签是否展示
  isShowCarbon: boolean = false; //抄送标签是否展示
  isShowTransfer: boolean = false; //抄送标签是否展示
  vis_more: boolean = false; //show more

  tabLoading: boolean = false;
  @Prop({
    type: Object,
    default: () => {}
  })
  eleObj;

  @Prop({
    type: Boolean,
    default: true
  })
  isShowMore;

  get userInfo() {
    return this.$store.getters.userInfo;
  }

  //手风琴切换事件
  openWorkflowCenter(val) {
    if (val === 1) {
      this.workflowSeize = 24 - this.workflowSeize;
    }
  }

  showMore() {
    let name;
    switch (this.workFlowName) {
      case 'wait':
        name = 'waitList';
        this.$router.push({
          path: '/front2/' + name,
          query: {
            showback: '1'
          }
        });
        break;
      case 'start':
        name = 'startedList';
        this.$router.push({
          path: '/front2/' + name,
          query: {
            showback: '1'
          }
        });
        break;
      case 'done':
        name = 'doneList';
        this.$router.push({
          path: '/front2/' + name,
          query: {
            showback: '1'
          }
        });
        break;
      case 'finished':
        name = 'end';
        this.$router.push({ path: '/flow/more/' + name });
        break;
      case 'carbon':
        name = 'cc';
        this.$router.push({ path: '/flow/more/' + name });
        break;
      case 'transfer':
        name = 'delegate';
        this.$router.push({ path: '/flow/more/' + name });
        break;
      case 'possibility':
        name = 'possibilityList';
        this.$router.push({
          path: '/front2/' + name,
          query: {
            showback: '1'
          }
        });
        break;
    }
  }

  /**
   * 跳转
   * @param row
   */
  openApprove(row) {
    if (row.processId == 'JUMP') {
      let url: string = row.jumpUrl + '&token=' + TokenUtil.getToken();
      window.open(url, '_blank');
    } else {
      let id = row.id;
      row.readStatus = true;
      let routeUrl: any = {};
      // 需要切换公司
      if (['wait', 'done', 'finished'].includes(this.workFlowName as string)) {
        // 如果是同一个公司
        if (row.performer == UserUtil.getUser().id) {
          if (this.workFlowName === 'wait') {
            routeUrl = this.$router.resolve({ path: '/front2/undo', query: { id, seeType: 'form' } });
          } else if (this.workFlowName === 'done') {
            // 已办
            routeUrl = this.$router.resolve({ path: '/front2/done', query: { id, seeType: 'form' } });
          } else if (this.workFlowName === 'finished') {
            // 办结
            routeUrl = this.$router.resolve({ path: '/flow/finish', query: { id } });
          }

          // 跳转 默认打开新窗口
          window.open(routeUrl.href, '_blank');
        } else {
          let frontType: string = '';
          if (this.workFlowName === 'wait') {
            frontType = '1';
          } else if (this.workFlowName === 'done') {
            frontType = '2';
          } else if (this.workFlowName === 'finished') {
            frontType = '3';
          }
          // 不同
          routeUrl =
            process.env.VUE_APP_SERVER_CODE +
            'auth/switchAccountByprocess?clientId=' +
            process.env.VUE_APP_CLIENT_ID +
            '&companyId=' +
            row.tenantId +
            '&id=' +
            id +
            '&token=' +
            TokenUtil.getToken() +
            '&seeType=form' +
            '&frontType=' +
            frontType;
          // 跳转 默认打开新窗口
          window.open(routeUrl, '_blank');
        }
      } else {
        if (this.workFlowName === 'start') {
          // 发起
          routeUrl = this.$router.resolve({ path: '/front2/request', query: { id, seeType: 'form' } });
        } else if (this.workFlowName === 'carbon') {
          // 抄送
          routeUrl = this.$router.resolve({ path: '/flow/cc', query: { id } });
        } else if (this.workFlowName === 'transfer') {
          // 转办
          routeUrl = this.$router.resolve({ path: '/flow/delegate', query: { id } });
        } else if (this.workFlowName === 'possibility') {
          // 提前预知事项
          routeUrl = this.$router.resolve({ path: '/front2/possibility', query: { id, seeType: 'form' } });
        }

        // 跳转 默认打开新窗口
        window.open(routeUrl.href, '_blank');
      }
    }
  }

  openApprove2(row) {
    if (this.workFlowName == 'wait' || this.workFlowName == 'start') {
      // 待办和发起
      let params = {
        joinTaskParamsOpenable: true,
        processId: row.processId,
        processInstId: row.processInstId,
        taskDefId: row.taskDefId,
        taskInstId: row.taskInstId,
        arguments: 'operation=' + this.workFlowName
      };
      this.homePage.goAddress(params).then(res => {
        if (res.code == 1) {
          window.open(res.data);
        }
      });
    } else if (this.workFlowName == 'carbon') {
      // 抄送
      let params = {
        joinTaskParamsOpenable: true,
        processId: row.processId,
        processInstId: row.processInstId,
        taskDefId: row.taskDefId,
        taskInstId: row.taskInstId,
        arguments: 'operation=' + this.workFlowName + '&carbonId=' + row.id
      };
      this.homePage.goAddress(params).then(res => {
        if (res.code == 1) {
          window.open(res.data);
        }
      });
    } else if (this.workFlowName == 'transfer') {
      // 抄送
      let params = {
        joinTaskParamsOpenable: true,
        processId: row.processId,
        processInstId: row.processInstId,
        taskDefId: row.taskDefId,
        taskInstId: row.taskInstId,
        arguments: 'operation=' + this.workFlowName
      };
      this.homePage.goAddress(params).then(res => {
        if (res.code == 1) {
          window.open(res.data);
        }
      });
    } else {
      // 已办和办结
      let params = {
        joinTaskParamsOpenable: true,
        processId: row.processId,
        processInstId: row.processInstId,
        taskDefId: row.lastSubmitTaskDefId,
        taskInstId: row.lastSubmitTaskInstId,
        arguments: 'operation=' + this.workFlowName
      };
      this.homePage.goAddress(params).then(res => {
        if (res.code == 1) {
          window.open(res.data);
        }
      });
    }
  }

  // 获取待办
  getWait() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getRuntime(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.todoList = res.data.list;
          this.todoTotal = res.data.total;
        } else {
          this.todoList = [];
          this.todoTotal = 0;
        }
      } else {
        this.todoList = [];
        this.todoTotal = 0;
        this.$store.commit('home/todo', []);
        this.$message.error(res.message);
      }
    });
  }

  // 获取发起
  getStart() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getStart(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.startList = res.data.list;
          this.startTotal = res.data.total;
        } else {
          this.startList = [];
          this.startTotal = 0;
        }
      } else {
        this.startList = [];
        this.startTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  // 获取已办
  getDone() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getCompleted(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.doneList = res.data.list;
          this.doneTotal = res.data.total;
        } else {
          this.doneList = [];
          this.doneTotal = 0;
        }
      } else {
        this.doneList = [];
        this.doneTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  // 获取完结
  getFinished() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getEnd(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.finishedList = res.data.list;
          this.finishedTotal = res.data.total;
        } else {
          this.finishedList = [];
          this.finishedTotal = 0;
        }
      } else {
        this.finishedList = [];
        this.finishedTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  // 获取抄送
  getCarbon() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getCc(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.carbonList = res.data.list;
          this.carbonTotal = res.data.total;
        } else {
          this.carbonList = [];
          this.carbonTotal = 0;
        }
      } else {
        this.carbonList = [];
        this.carbonTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  // 获取转办
  getTransfer() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getDelegate(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.transferList = res.data.list;
          this.transferTotal = res.data.total;
        } else {
          this.transferList = [];
          this.transferTotal = 0;
        }
      } else {
        this.transferList = [];
        this.transferTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  // 获取提前预知事项
  getPossibility() {
    let params = {
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getPossibility(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.possibilityList = res.data.list;
          this.possibilityTotal = res.data.total;
        } else {
          this.possibilityList = [];
          this.possibilityTotal = 0;
        }
      } else {
        this.possibilityList = [];
        this.possibilityTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  //获取
  getWorkFlowList(type) {
    this.pageIndex = 1;
    // 获取待办
    if (type == 'wait' || !type) {
      this.getWait();
    }
    // 获取发起
    if (type == 'start' || !type) {
      this.getStart();
    }
    // 获取已办
    if (type == 'done' || !type) {
      this.getDone();
    }
    // 获取完结
    if (type == 'finished' || !type) {
      this.getFinished();
    }
    // 获取转办
    if (type == 'transfer' || !type) {
      this.getTransfer();
    }
    // 获取抄送
    if (type == 'carbon' || !type) {
      this.getCarbon();
    }
    // 获取抄送
    if (type == 'possibility' || !type) {
      this.getPossibility();
    }
    this.$emit('changeActiveName', type);
  }

  proMore() {
    if (this.workFlowName == 'wait') {
      this.$router.push('/flow/todo');
    } else if (this.workFlowName == 'start') {
      this.$router.push('/flow/start');
    } else if (this.workFlowName == 'done') {
      this.$router.push('/flow/done');
    } else if (this.workFlowName == 'finished') {
      this.$router.push('/flow/finished');
    } else if (this.workFlowName == 'carbon') {
      this.$router.push('/flow/carbon');
    } else if (this.workFlowName == 'transfer') {
      this.$router.push('/flow/transfer');
    }
  }

  formatterDate(val) {
    if (val) {
      let date = DateUtil.parse(val);
      return DateUtil.format(date, 'yyyy-MM-dd HH:mm:ss');
    }
  }

  receiveMessage(event) {
    console.log('---------get message from ' + event.origin + ',and message is ' + event.data);
    // 我们能相信信息的发送者吗?  (也许这个发送者和我们最初打开的不是同一个页面).
    // if (event.origin !== "https://sub.cncecoa.com" && event.origin !== "http://114.247.88.95:33080")
    //   return;
    this.getWorkFlowList(this.workFlowName);
  }

  initFlowList() {
    this.tabLoading = true;
    //   初始化流程数据
    this.getWait();
    this.getStart();
    this.getDone();
    this.getFinished();
    this.getTransfer();
    this.getCarbon();
    this.getPossibility();
    setTimeout(() => {
      this.tabLoading = false;
    }, 500);
  }

  // 接收消息 刷新列表
  receiveMessage2(e) {
    if (e.data && e.data.length) {
      const message = JSON.parse(e.data);
      if (message.key == 'viewerSubmitSuccess') {
        this.initFlowList();
      }
    }
  }

  mounted() {
    // window.addEventListener('message', this.receiveMessage, false);
    window.addEventListener('message', this.receiveMessage2, false);
  }
  timer: any = null;
  created() {
    this.initFlowList();
    this.timer = setInterval(() => {
      this.initFlowList();
    }, 60000);
  }
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
}
